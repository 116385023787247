<template>
  <div class="project-add">
    <div class="container fluid">
      <div class="card">
        <h1 class="mainTitle lineBottom py-3 mb-2">New Project</h1>
        <div class="p-1 sm-p-2 md-p-3">
          <create-project-form
            type="agency"
            :isLoading.sync="isFormLoading"
            :project="project"
            :bankers="bankers"
            :lawyers="lawyers"
            :createProjectAPI="projectAPI.createProject"
          >
            <project-form-content
              type="agency"
              :formData.sync="project"
              :isLoading.sync="isFormLoading"
              :getProjectAPI="projectAPI.getProject"
              :getFacilitiesAPI="propertyAPI.getFacilities"
              :getAmenitiesAPI="propertyAPI.getAmenities"
              :getPropertyTagsAPI="propertyAPI.getPropertyTags"
              :getDevelopersAPI="developerAPI.getDevelopers"
            ></project-form-content>
            <assign-bankers
              v-model="bankers"
              required
              type="agency"
              :mapValue="(val) => val.id"
            ></assign-bankers>
            <assign-lawyers
              v-model="lawyers"
              required
              type="agency"
              :mapValue="(val) => val.id"
            ></assign-lawyers>
          </create-project-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { developer as developerAPI, property as propertyAPI } from "@/api";

import ProjectAPI from "@/modules/Project/api/project";
const projectAPI = new ProjectAPI("agency");

export default {
  components: {
    AssignBankers: () =>
      import("@/modules/Project/components/AssignBankers/AssignToProject"),
    AssignLawyers: () =>
      import("@/modules/Project/components/AssignLawyers/AssignToProject"),
    CreateProjectForm: () =>
      import("@/modules/Project/components/CreateProjectForm"),
    ProjectFormContent: () =>
      import("@/modules/Project/components/ProjectFormContent")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      isFormLoading: false,
      project: {},
      bankers: [],
      lawyers: [],

      projectAPI,
      developerAPI,
      propertyAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-add {
  @extend %formDesign;
}
</style>
